<template>
    <div :style="cssStyle ? cssStyle: 'width:100%;'">
        <div class="chart-wrapper">
            <div class="chart-datetime" v-if="settings.inputEnabled">
                <date-picker
                    v-model="fromDate"
                    placeholder="از تاریخ"
                    appendTo="body"
                    class="chartFromDate"
                    inputClass="Datepicker"
                    :clearable="true"
                    :autoSubmit="true"
                    @change="chartDatesChanged()"
                ></date-picker>

                <date-picker
                    v-model="toDate"
                    placeholder="تا تاریخ"
                    appendTo="body"
                    class="chartEndDate"
                    inputClass="Datepicker"
                    :clearable="true"
                    :autoSubmit="true"
                    @change="chartDatesChanged()"
                ></date-picker>
            </div>
                
            <highcharts class="chart-element" :constructor-type="'stockChart'" :options="chartOptions" :callback="chartCallback" v-if="chartOptions"></highcharts>
        </div>
    </div>
</template>

<style>
    .chart-datetime {
        direction: rtl;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        z-index: -99999999;
    }
</style>

<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'

import stockInit from 'highcharts/modules/stock'
import loadIndicatorsAll from 'highcharts/indicators/indicators-all'

stockInit(Highcharts)
loadIndicatorsAll(Highcharts)

export default {
    name: 'HighCharts',
    components: {
        highcharts: Chart
    },
    props: ['options','cssStyle','chartHeight'],
    data: function () {
        return {
            chart: null,
            settings: {},
            chartOptions: {},
            fromDate: null,
            toDate: null,
        }
    },

    mounted() {
        this.initialize();

        this.$root.$on('widgetSizeChanged', () => {
            setTimeout(() => {
                let chartWrapper = $(this.chart.container).closest('.chart-wrapper');

                this.chart.setSize(chartWrapper.width(), null);
            }, 100);
        });
    },

    methods: {
        initialize() {
            let defaultValues = {
                lang: 'fa',
                chartData: null,
                chartType: "line",
                multipleCharts: false,
                compare: false,
                chartTitle: "",
                yAxisTitle: "",
                fillOpacity: null,
                gridLineRemove: null,
                xAxisGridLineWidth: 0,
                gridLineStyle: 'Solid',
                buttonEnabled: true,
                buttonDefault: 0,
                inputEnabled: false,
                navigatorEnabled: true,
                navigatorMaskFill: 'rgba(134, 134, 134, 0.31)',
                scrollbarEnabled: true,
                series: false,
                backgroundColor: '#ffffff',
                plotColor: "#00B5B8",
                upColor: "#ffffff",
                pattern: false,
                chartHeight: this.chartHeight,
                legend: false,
                legendLayout: 'horizontal',
                legendTitle: "",
                tooltipTitle: 'ارزش',
                tooltipOnlyTime: false,
                tooltipAdDate: false,
                tooltipPadding: 5,
                tooltipFormatter: null,
                plotShadow: true,
                markerEnabled: false,
                markerRadius: 3,
                markerFillColor: null,
                markerLineColor: null,
                markerLineWidth: null,
                FillColorGradient: null,
                id: false,
                indicators: false,
            };

            this.settings = this.$helpers.unbindObject(this.options);

            for (let [key, value] of Object.entries(defaultValues)) {
                if ( typeof this.settings[key] == 'undefined' ) {
                    this.settings[key] = value;
                }
            }

            this.config();
        },

        config() {
            let self = this;
            
            if ( !self.settings.navigatorEnabled ) {
                self.settings.scrollbarEnabled = false;
            }

            if (!self.settings.tooltipFormatter) {
                self.settings.tooltipFormatter = function () {
                    var tooltip_array = [false];
                    var tooltip_index = 1;
                    $.each(this.points, function () {
                        var dateTime = (self.settings.lang == 'fa') ? new JDate(this.x) : new Date(this.x);
                        let chart_tooltip_jdate = '';
                        let chart_tooltip_ADdate = '';
                        if (self.settings.tooltipOnlyTime) {
                            var hours = dateTime.getHours();
                            var minutes = dateTime.getMinutes();
                            chart_tooltip_jdate = hours + ":" + minutes;
                        } else {
                            if (self.settings.lang == 'fa') {
                                var year = dateTime.getFullYear();
                                var month = dateTime.getMonth() + 1;
                                var day = dateTime.getDate();
                                chart_tooltip_jdate = year + "/" + month + "/" + day;
                            }

                            if (self.settings.tooltipAdDate || self.settings.lang != 'fa') {
                                var dateTime_AD = new Date(this.x);
                                var year_AD = dateTime_AD.getFullYear();
                                var month_AD = dateTime_AD.getMonth() + 1;
                                var day_AD = dateTime_AD.getDate();
                                chart_tooltip_ADdate = "<div><span>" + year_AD + "/" + month_AD + "/" + day_AD + "</span></div>";
                            }
                        }

                        let tooltip_html;
                        if (self.settings.chartType == "candlestick") {
                            if (this.series.userOptions.type == 'rsi') {
                                tooltip_html =
                                    `<div style="text-align: left;">
                                        <div><span style="color: ${this.point.color}">●</span> <strong>RSI (14):</strong> ${self.$helpers.number_format_decimal(this.y, 2)}</div>
                                    </div>`;
                            } else if (this.series.userOptions.type == 'sma') {
                                tooltip_html =
                                    `<div style="text-align: left;">
                                        <div><span style="color: ${this.point.color}">●</span> <strong>SMA (14):</strong> ${self.$helpers.number_format_decimal(this.y, 2)}</div>
                                    </div>`;
                            } else if (this.series.userOptions.type == 'ema') {
                                tooltip_html =
                                    `<div style="text-align: left;">
                                        <div><span style="color: ${this.point.color}">●</span> <strong>EMA (14):</strong> ${self.$helpers.number_format_decimal(this.y, 2)}</div>
                                    </div>`;
                            } else if (this.series.userOptions.type == 'stochastic') {
                                tooltip_html = 
                                    `<div style="text-align: left;">
                                        <div><span style="color: ${this.point.color}">●</span> <strong>Stochastic (14, 3)</strong></div>
                                        <div><strong>%K:</strong> ${self.$helpers.number_format_decimal(this.point.y, 2)}</div>
                                        <div><strong>%D:</strong> ${self.$helpers.number_format_decimal(this.point.smoothed, 2)}</div>
                                    </div>`;
                            } else if (this.series.userOptions.type == 'macd') {
                                tooltip_html =
                                    `<div style="text-align: left;">
                                        <div><span style="color: ${this.point.color}">●</span> <strong>MACD (26, 12, 9)</strong></div>
                                        <div><strong>Value:</strong> ${self.$helpers.number_format_decimal(this.point.MACD, 2)}</div>
                                        <div><strong>Signal:</strong> ${self.$helpers.number_format_decimal(this.point.signal, 2)}</div>
                                        <div><strong>Histogram:</strong> ${self.$helpers.number_format_decimal(this.point.y, 2)}</div>
                                    </div>`;
                            } else {
                                tooltip_html =
                                    `<div>
                                        <div class="msHighcharts-tooltip-top" style="text-align: right;margin-bottom: 5px;">
                                            <div>
                                                <div><strong>باز:</strong> ${self.$helpers.number_format_decimal(this.point.open, 2)}</div>
                                            </div>
                                            <div>
                                                <div><strong>بیشترین:</strong> ${self.$helpers.number_format_decimal(this.point.high, 2)}</div>
                                            </div>
                                            <div>
                                                <div><strong>کمترین:</strong> ${self.$helpers.number_format_decimal(this.point.low, 2)}</div>
                                            </div>
                                            <div>
                                                <div><strong>پایانی:</strong> ${self.$helpers.number_format_decimal(this.point.close, 2)}</div>
                                            </div>
                                        </div>
                                        <div class="msHighcharts-tooltip-bottom">
                                            <strong>${chart_tooltip_jdate}</strong>
                                        </div>
                                    </div>`;
                            }
                        } else {
                            var tooltipTitle = ( self.settings.multipleCharts ) ? this.series.name : self.settings.tooltipTitle;
                            
                            tooltip_html =
                                `<div style="text-align: right;">
                                    <div class="msHighcharts-tooltip-top">
                                    <div><strong>${chart_tooltip_jdate}</strong></div>
                                        ${chart_tooltip_ADdate}
                                    </div>
                                    <div class="msHighcharts-tooltip-bottom">
                                        <div><strong>${tooltipTitle}:</strong> ${self.$helpers.number_format_decimal(this.y, 2)}</div>
                                    </div>
                                </div>`;
                        }

                        tooltip_array[tooltip_index] = tooltip_html;
                        tooltip_index++;
                    });

                    return tooltip_array;
                }
            }

            let series;
            if ( self.settings.series ) {
                series = self.settings.series;
            } else {
                series = [{
                    name: self.settings.legendTitle,
                    data: self.settings.chartData,
                    threshold: null,
                    tooltip: {
                        valueDecimals: 0,
                        useHTML: true
                    },
                    marker: {
                        enabled: self.settings.markerEnabled,
                        radius: self.settings.markerRadius,
                        fillColor: self.settings.markerFillColor,
                        lineColor: self.settings.markerLineColor,
                        lineWidth: self.settings.markerLineWidth
                    },
                    shadow: self.settings.plotShadow
                }];

                if ( self.settings.id ) {
                    series[0].id = self.settings.id;
                }

                if ( self.settings.indicators ) {
                    for ( let series_item of self.settings.indicators ) {
                        if (typeof series_item.linkedTo != 'undefined') {
                            series_item.linkedTo = self.settings.id;
                        }
                    }

                    series = series.concat(self.settings.indicators);
                }
            }

            let gridLineRemove;
            if ( self.settings.gridLineRemove != null ) {
                gridLineRemove = 0;
            } else {
                gridLineRemove = 1;
            }

            let yAxis;
            if ( self.settings.indicators ) {
                yAxis = [{
                    title: {
                        text: self.settings.yAxisTitle,
                        useHTML: Highcharts.hasBidiBug
                    },
                    gridLineWidth: gridLineRemove,
                    gridLineDashStyle: self.settings.gridLineStyle,
                    opposite: true,
                    height: '300px',
                    labels: {
                        formatter: function () {
                            return this.value;
                        }
                    },
                }, {
                    lineWidth: 2,
                    title: {
                        text: 'RSI'
                    },
                    top: '405px',
                    height: '200px',
                    offset: 0,
                    plotLines: [{
                        value: 30,
                        color: '#7b137b',
                        dashStyle: 'shortdash',
                        width: 1,
                        label: {
                            text: '30'
                        },
                        zIndex: 1
                    }, {
                        value: 70,
                        color: '#7b137b',
                        dashStyle: 'shortdash',
                        width: 1,
                        label: {
                            text: '70'
                        },
                        zIndex: 1
                    }],
                    plotBands: [{
                        from: 30,
                        to: 70,
                        color: 'rgba(146, 45, 146, 0.2)',
                    }],
                    labels: {
                        align: 'left',
                        enabled: true,
                    },
                }, {
                    lineWidth: 2,
                    title: {
                        text: 'Stochastic'
                    },
                    top: '630px',
                    height: '200px',
                    offset: 0,
                    plotLines: [{
                        value: 20,
                        color: '#0a0aff',
                        dashStyle: 'shortdash',
                        width: 1,
                        label: {
                            text: '20'
                        }
                    }, {
                        value: 80,
                        color: '#0a0aff',
                        dashStyle: 'shortdash',
                        width: 1,
                        label: {
                            text: '80'
                        }
                    }],
                    plotBands: [{
                        from: 20,
                        to: 80,
                        color: 'rgba(92, 92, 255, 0.2)',
                    }],
                    labels: {
                        align: 'left',
                        enabled: true,
                    },
                }, {
                    lineWidth: 2,
                    title: {
                        text: 'MACD'
                    },
                    top: '855px',
                    height: '200px',
                    labels: {
                        align: 'left',
                        enabled: true,
                    },
                    offset: 0,
                }];
            } else {
                yAxis = {
                    title: {
                        text: self.settings.yAxisTitle,
                        useHTML: Highcharts.hasBidiBug
                    },
                    gridLineWidth: gridLineRemove,
                    gridLineDashStyle: self.settings.gridLineStyle,
                    opposite: true,
                    labels: {
                        formatter: function () {
                            return this.value;
                        }
                    }
                };
            }

            var fillColor = null;
            if ( self.settings.pattern && self.settings.pattern == 'striped' ) {
                fillColor = {
                    pattern: {
                        id: 'chart-pattern-' + new Date().getTime(),
                        path: {
                            d: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
                            strokeWidth: 1
                        },
                        width: 4,
                        height: 4,
                        opacity: 0.3
                    }
                };
            }

            if ( self.settings.FillColorGradient != null ) {
                fillColor = {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, self.settings.FillColorGradient],
                        [1, Highcharts.Color(self.settings.FillColorGradient).setOpacity(0).get('rgba')]
                    ],
                }
            }

            var Highcharts_GlobalOpts = {
                lang: {
                    printChart: "پرینت نمودار",
                    downloadCSV: "دانلود فایل با فرمت CSV",
                    downloadJPEG: "دانلود عکس با فرمت JPEG",
                    downloadPDF: "دانلود فایل با فرمت PDF",
                    downloadPNG: "دانلود عکس با فرمت PNG",
                    downloadSVG: "دانلود عکس با فرمت SVG",
                    loading: "درحال بارگزاری",
                    noData: "دیتایی برای نمایش وجود ندارد",
                    rangeSelectorMinInput: "تاریخ ابتدا",
                    rangeSelectorMaxInput: "تاریخ پایان"
                },
                time: {
                    timezoneOffset: new Date().getTimezoneOffset()
                }
            };

            if ( self.settings.lang == 'fa' ) {
                Highcharts_GlobalOpts.lang.months = ["فروردين", "ارديبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
                Highcharts_GlobalOpts.lang.shortMonths = ["فروردين", "ارديبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
                Highcharts_GlobalOpts.lang.weekdays = ["یکشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنج‌شنبه", "جمعه", "شنبه"];
            }

            Highcharts.setOptions(Highcharts_GlobalOpts);

            let main_data_object = {
                chart: {
                    type: self.settings.chartType,
                    backgroundColor: self.settings.backgroundColor,
                    height: self.settings.chartHeight
                },

                time: {
                    Date: JDate
                },

                credits: {
                    enabled: false
                },

                tooltip: {
                    formatter: self.settings.tooltipFormatter,
                    padding: self.settings.tooltipPadding,
                    useHTML: true
                },

                navigator: {
                    maskFill: self.settings.navigatorMaskFill,
                    enabled: self.settings.navigatorEnabled
                },

                scrollbar: {
                    enabled: self.settings.scrollbarEnabled
                },

                legend: {
                    enabled: self.settings.legend,
                    layout: self.settings.legendLayout,
                    useHTML: Highcharts.hasBidiBug
                },

                rangeSelector: {
                    allButtonsEnabled: false,
                    buttons: [{
                        type: "all",
                        text: "همه"
                    }, {
                        type: "year",
                        count: 1,
                        text: "یک سال"
                    }, {
                        type: "ytd",
                        text: "سال جاری"
                    }, {
                        type: "month",
                        count: 6,
                        text: "شش ماه"
                    }, {
                        type: "month",
                        count: 3,
                        text: "سه ماه"
                    }, {
                        type: "month",
                        count: 1,
                        text: "یک ماه"
                    }],
                    buttonTheme: {
                        width: 60
                    },
                    selected: self.settings.buttonDefault,
                    enabled: self.settings.buttonEnabled,
                    inputEnabled: self.settings.inputEnabled
                },

                exporting: {
                    allowHTML: true
                },

                labels: {
                    useHTML: Highcharts.hasBidiBug
                },

                xAxis: {
                    gridLineWidth: self.settings.xAxisGridLineWidth
                },

                yAxis: yAxis,

                title: {
                    text: self.settings.chartTitle,
                    useHTML: Highcharts.hasBidiBug
                },

                plotOptions: {
                    series: {
                        color: self.settings.plotColor,
                        fillColor: fillColor,
                        fillOpacity: self.settings.fillOpacity,
                        upColor: self.settings.upColor,
                        compare: self.settings.compare,
                        // compareStart: true,
                        showInNavigator: true
                    },
                },

                series: series
            };

            this.chartOptions = main_data_object;
        },

        chartCallback(chart) {
            setTimeout(() => {
                this.chart = chart;
                let chartContainer = $(this.chart.container);

                chartContainer.find(".highcharts-range-selector-buttons text").eq(0).text("محدوده").css("font-size", "10px");
                chartContainer.find(".highcharts-range-label text").eq(0).html("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;از");
                chartContainer.find(".highcharts-range-label text").eq(1).text("تا");

                this.setDatePickers();

                setTimeout(() => {
                    this.chart.setSize(chartContainer.closest('.chart-wrapper').width(), null);
                }, 1000);
            }, 100);

            if ( this.settings.inputEnabled ) {
                //
            }
        },

        setDatePickers() {
            let extremes = this.chart.xAxis[0].getExtremes();
            this.fromDate = new JDate(extremes.dataMin).toLocaleString().split(' ')[0];
            this.toDate = new JDate(extremes.dataMax).toLocaleString().split(' ')[0];
        },

        chartDatesChanged() {
            // let start_timestamp = ( parin_lang == 'fa' ) ? new JDate(this.fromDate).getTime() : new Date(this.fromDate).getTime();
            let start_timestamp = new JDate(this.fromDate).getTime();
            
            // let end_timestamp = ( parin_lang == 'fa' ) ? new JDate(this.toDate).getTime() : new Date(this.toDate).getTime();
            let end_timestamp = new JDate(this.toDate).getTime();

            this.chart.xAxis[0].setExtremes(start_timestamp, end_timestamp);
        }
    }
}
</script>